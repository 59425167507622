<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper container-xxl p-0">
        <banner
          title="Income Tax Return Filing"
          :breadcrumb="[
            { label: 'Choose Category ', url: 'http://localhost:8080/retailer/client' },
          ]"
        >
        </banner>

        <!-- Services start -->
        <div class="row" style="margin-top: -45px">
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(1, 'individual')">
                  <!-- <i data-feather='user' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/INDIVIDUAL.png"
                    alt=""
                  />
                  <p><strong style="color: black">INDIVIDUAL</strong></p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(2, 'partnership')" class="text-service">
                  <!-- <i data-feather='users' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/PARTNERSHIP.png"
                    alt=""
                  />

                  <p>PARTNERSHIP</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(3, 'society')" class="text-service">
                  <!-- <i data-feather='dollar-sign' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/COOPERATIVE SOCIETY.png"
                    alt=""
                  />

                  <p>COOPERATIVE SOCIETY</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(4, 'AOP/BOI')" class="text-service">
                  <!-- <i data-feather='user-plus' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/AOP-BOI.png"
                    alt=""
                  />

                  <p>AOP/ BOI</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(5, 'HUF')" class="text-service">
                  <!-- <i data-feather='user-plus' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/HUF.png"
                    alt=""
                  />

                  <p>HUF</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(6, 'COMPANY PUBLIC')" class="text-service">
                  <!-- <i data-feather='user-plus' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/COMPANY PUBLIC.png"
                    alt=""
                  />

                  <p>COMPANY PUBLIC</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(7, 'COMPANY PRIVATE')" class="text-service">
                  <!-- <i data-feather='user-plus' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/COMPANY PRIVATE.png "
                    alt=""
                  />

                  <p>COMPANY PRIVATE</p>
                </a>
              </div>
            </div>
          </div>
          <div class="col text-center" style="padding: 5px 5px 5px 5px">
            <div class="card">
              <div class="card-body">
                <a @click="itrType(8, 'LLP')" class="text-service">
                  <!-- <i data-feather='user-plus' class="size-18"></i> -->
                  <img
                    style="width: 50px"
                    src="/assets/image/services-icone/LLP.png"
                    alt=""
                  />

                  <p>LLP</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--/ Services start -->

        <div class="row" style="padding-top: 35px">
          <div class="col-md-6">
            <h2 class="heading">FAQ's</h2>
            <div class="accordion accordion-margin accordion-border" id="accordionMargin">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingMarginOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionMarginOne"
                    aria-expanded="false"
                    aria-controls="accordionMarginOne"
                  >
                    <i data-feather="plus" class="accordian-plus"></i> What is Salary
                    Income?
                  </button>
                </h2>
                <div
                  id="accordionMarginOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingMarginOne"
                  data-bs-parent="#accordionMargin"
                >
                  <div class="accordion-body">
                    Pastry pudding cookie toffee bonbon jujubes jujubes powder topping.
                    Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer
                    lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy
                    caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie
                    biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar
                    plum jelly beans tiramisu icing cheesecake.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingMarginTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionMarginTwo"
                    aria-expanded="false"
                    aria-controls="accordionMarginTwo"
                  >
                    <i data-feather="plus" class="accordian-plus"></i> What is Capital
                    Gain?
                  </button>
                </h2>
                <div
                  id="accordionMarginTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingMarginTwo"
                  data-bs-parent="#accordionMargin"
                >
                  <div class="accordion-body">
                    Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll
                    topping fruitcake. Caramels liquorice biscuit ice cream fruitcake
                    cotton candy tart. Donut caramels gingerbread jelly-o gingerbread
                    pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie
                    carrot cake.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingMarginThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionMarginThree"
                    aria-expanded="false"
                    aria-controls="accordionMarginThree"
                  >
                    <i data-feather="plus" class="accordian-plus"></i> What is House
                    Property Income?
                  </button>
                </h2>
                <div
                  id="accordionMarginThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingMarginThree"
                  data-bs-parent="#accordionMargin"
                >
                  <div class="accordion-body">
                    Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly
                    jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame
                    snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin
                    chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow
                    pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping
                    apple pie pie croissant cotton candy dessert tiramisu.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingMarginFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionMarginFour"
                    aria-expanded="false"
                    aria-controls="accordionMarginFour"
                  >
                    <i data-feather="plus" class="accordian-plus"></i> What is Business
                    Income?
                  </button>
                </h2>
                <div
                  id="accordionMarginFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingMarginFour"
                  data-bs-parent="#accordionMargin"
                >
                  <div class="accordion-body">
                    Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies
                    chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu
                    marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping
                    jelly beans brownie jujubes muffin. Donut croissant jelly-o cake
                    marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll
                    sweet cupcake.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingMarginFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#accordionMarginFive"
                    aria-expanded="false"
                    aria-controls="accordionMarginFive"
                  >
                    <i data-feather="plus" class="accordian-plus"></i> What are Other
                    Sources of Income?
                  </button>
                </h2>
                <div
                  id="accordionMarginFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingMarginFive"
                  data-bs-parent="#accordionMargin"
                >
                  <div class="accordion-body">
                    Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies
                    chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu
                    marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping
                    jelly beans brownie jujubes muffin. Donut croissant jelly-o cake
                    marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll
                    sweet cupcake.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h2 class="heading">Videos</h2>
            <div class="video-player" id="plyr-video-player" style="margin: 10px">
              <iframe
                src="https://www.youtube.com/embed/femaL6lGw1o"
                width="100%"
                height="360"
                allowfullscreen
                allow="autoplay"
              ></iframe>
            </div>
            <!-- <iframe class="elementor-video" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" width="640" height="360" src="https://www.youtube.com/embed/femaL6lGw1o?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapk.mudhrape.com&amp;widgetid=1" id="widget2"></iframe> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../../../components/retailer/comman/Banner.vue";
export default {
  name: "Itr",
  components: {
    Banner,
  },
  data() {
    return {
      // termCondition: 'false1'
    };
  },
  methods: {
    itrType(serviceModuleId, itrType) {
      var itr = {};
      // itr.itrType = itrType
      // itr.serviceModulesIds = []
      // itr.serviceModulesIds.push(serviceModuleId)
      itr.service_id = 38;
      localStorage.setItem("itr", JSON.stringify(itr));
      // alert(serviceModuleId)
      //      alert(itrType)
      if (itrType == "LLP") {
        // alert('llp 2')
        // this.$router.push({path: '/llp2'})
        this.$router.push({ name: "llp2Component" });
      } else if (serviceModuleId == 1 && itrType == "individual") {
        //    alert('itr 2')
        this.$router.push({ name: "Individual2" });
      } else if (itrType == "partnership") {
        this.$router.push({ name: "Partnership2" });
      } else if (itrType == "society") {
        this.$router.push({ name: "Society2" });
      } else if (serviceModuleId == 4 && itrType == "AOP/BOI") {
        this.$router.push({ name: "Aop2" });
      } else if (itrType == "HUF") {
        // this.$router.push('/huf2')
        this.$router.push({ name: "Huf2" });
      } else if (serviceModuleId == 6 && itrType == "COMPANY PUBLIC") {
        // this.$router.push('/company_public2')
        this.$router.push({ name: "CompanyPublic2" });
      } else if (serviceModuleId == 7 && itrType == "COMPANY PRIVATE") {
        this.$router.push({ name: "CompanyPrivate2" });
      } else {
        this.$swal.fire({
          position: "top-end",
          icon: "warning",
          title: "This Service not avalible now.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>

<style scoped>
.size-18 {
  width: 40px;
  height: 40px;
  stroke: red;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.accordian-plus {
  width: 20px;
  height: 20px;
  stroke: red;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.card {
  /* margin: 5px; */
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
  padding: 10px 0px 10px 0px;
  margin: 5px 5px -10px 5px;
  /* box-sizing: border-box; */
  /* display: flex; */
  height: 100%;
}

.card-body {
  flex: 0;
  padding: 0;
  margin: 0;
}

.p-text {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.bg-image {
  background-color: #fff;
  background-image: url(https://apk.mudhrape.com/wp-content/uploads/2022/01/background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 0 120px;
  /* text-align: center; */
  color: white;
  /* background-size: 100% 100%;
    width: 100%; */
}

.h2-heading {
  color: white;
  font-family: poppins, Sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 767px) {
  .h2-heading {
    font-size: 22px;
  }
}

.h3-heading {
  color: white;
  font-family: poppins, Sans-serif;
  font-size: 25px;
  font-weight: 600;
  padding: 20px 0 0;
  text-align: center;
}

.text-service {
  color: black;
  font-family: poppins, Sans-serif;
  font-size: 14px;
  font-weight: 600;
  /* line-height: 1.2em; */
}

.heading {
  color: #00364f;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 20px 0;
}

@media (min-width: 992px) {
  .col-lg-1 {
    width: 10.33333%;
  }
}
</style>
